import React from "react";

import YouTube from "react-youtube";
import "./ShowTrailer.scss";
import close from "../../images/red-x.svg";
import { BsPlusLg, BsHandThumbsUp } from "react-icons/bs";
import DOMPurify from 'dompurify';

function ShowTrailer({ data, onClose }) {
  
  const opts = {
    height: "390",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  const formatHTML = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    return (
      <span dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
    );
  }
  
  const getMedia = () => {
    if(data.id){
      return(
        <YouTube opts={opts} videoId={data.id} />
        )
      }else{
      let mediaType = (!data.id && data.poster_path.indexOf("mp4") != -1) ? "video" : "image";
      if(mediaType == "image"){
        return(
          <img className="content-image"  src={"images/" + data.poster_path} />           
        )
      }else if(mediaType == "video"){
        return(
          <>
            <video className="content-image" src={"images/" + data.poster_path} autoplay muted loop controls></video>
          </>
        )
      }
    }
  };


  return (
    <>
    <div className="cover-content" onClick={onClose}></div>
    <div className="show-trailer">
        <div
            className="close-icon"
            onClick={onClose}
          >
            <img src={close} alt="close" />
        </div>
        {getMedia()}
        <div className="content-descr">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                  <div className="content-header">
                    <img className="ico-anbima" src="images/ico-anbima.svg" /> 
                    {data.category || "Educação"}
                  </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                  <p className="titulo">{data.original_name} 
                  <div className="icos">
                      <button className="btn ico text-white">
                        <BsPlusLg />
                      </button>
                      <button className="btn ico text-white">
                        <BsHandThumbsUp />
                      </button>
                  </div>                  
                  </p>
                  <p className="mb-3">{data.overview}</p>

                  <div>
                    
                    <p className="mb-3">
                    {data.new &&
                      <span className="txt-novo">
                        Novo 
                      </span>
                    }
                    &nbsp;&nbsp;&nbsp;{data.year}
                    &nbsp;&nbsp;&nbsp;{data.total_episodes}{data.total_episodes > 1 ? "s" : ""}
                    </p>

                    <div>

                      <div className={"box-rating mr-3 mb-3 box-" + data.rating}>
                        {data.rating} 
                      </div>
                      &nbsp;
                      {data.rating_description}
                    </div>
                  </div>
              </div>
              <div className="col-md-6">
                  {data.casting &&
                  <p className="mb-3"><strong>Elenco: </strong>{formatHTML(data.casting)}</p>
                  }
                  {data.genre &&
                  <p className="mb-3"><strong>Gênero: </strong>{formatHTML(data.genre)}</p>
                  }
                  {data.related &&
                  <p><strong>Títulos semelhantes: </strong>{formatHTML(data.related)}</p>
                  }
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default ShowTrailer;
