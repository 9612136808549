import React from "react";
import "./AppNavBar.scss";

function AppNavBar({user, setProfile}) {
  return (
    <>
    <nav  className="navbar navbar-expand-lg navbar-dark bg-black fixed-top">
      <div  className="container-fluid">
        <a className="navbar-brand" href="#">
          <img
          className="logo"
          src="images/anbimaflix.svg"
          alt="Anbimaflix Logo"
          />
        </a>

        <button  className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <span  className="navbar-toggler-icon"></span>
        </button>
        <div  className="collapse navbar-collapse" id="navbarText">
          <ul  className="navbar-nav me-auto mb-2 mb-lg-0">
            <li  className="nav-item">
              <a  className="nav-link active" aria-current="page" href="#">Início</a>
            </li>
            <li  className="nav-item">
              <a  className="nav-link" href="#">Séries</a>
            </li>
            <li  className="nav-item">
              <a  className="nav-link" href="#">Filmes</a>
            </li>
            <li  className="nav-item">
              <a  className="nav-link" href="#">Bombando</a>
            </li>
            <li  className="nav-item">
              <a  className="nav-link" href="#">Minha lista</a>
            </li>
            <li  className="nav-item">
              <a  className="nav-link" href="#">Navegar por idiomas</a>
            </li>
            
          </ul>
          <span  className="navbar-text p-0">
            <button className="btn text-white border me-3" onClick={setProfile}>Mudar perfil</button>
            <img
            className="avatar"
              src={user.poster_path}
              alt="Nanda"
              />
          </span>
        </div>
      </div>
  </nav>
    
    <div className="AppNavBar d-none">
      <img
        className="logo"
        src="images/anbimaflix.svg"
        alt="Anbimaflix Logo"
        />

      <div>
        <button className="btn text-white border me-3" onClick={setProfile}>Mudar perfil</button>
        <img
        className="avatar"
          src={user.poster_path}
          alt="Nanda"
          />
      </div>
    </div>
        </>
  );
}

export default AppNavBar;
