import React, { useEffect, useState } from "react";
import "./Banner.scss";
import Axios from "../../axios/axios";
import { API_KEY, imageUrl } from "../../constants/constants";
function Banner({onOpen, content}) {
  const [movie, setMovie] = useState();
  const [videoKey, setVideoKey] = useState();

  useEffect(() => {
    console.log(content);
    setMovie(content[0]);
  }, []);
  
  const handleMovieClick = (item) => {
    onOpen(movie);
  };

  return (
    <div
      className="banner"
      style={{
        backgroundImage: `url(${movie ? movie.backdrop_path : ""})`,
        backgroundPosition: `${movie ? (movie.banner_position || "") : ""}`
      }}
    >
      {/* <img
        src={movie ? imageUrl + movie.backdrop_path : ""}
        alt=""
      /> */}
      {movie ?     
      <> 
        <div className="content">
          {movie.logo
            ?      
          <h1 className="title">
              <div className="banner-header">
                  <>
                  <img className="ico-anbima" src="images/ico-anbima.svg" /> 
                  {movie.category || "Educação"}
                  <img className="img-logo"
                  src={movie.logo}
                  alt=""
                  />
                  </>
              </div>
          </h1>
          : 
            <>
            <h1 className="title mb-0 p-0">
              <div className="banner-header mb-2">
                  <div>
                    <img className="ico-anbima" src="images/ico-anbima.svg" /> 
                      {movie.category || "Educação"}
                  </div>
                </div>
            </h1>
            <h2 className="mb-5">
              {movie.name}
            </h2>
            </>
                
          }

          {movie.overview ?
          <>
            <div className="banner_buttons">
              <button className="button"
              onClick={handleMovieClick}          
              >Comece aqui</button>
            </div>
            <h2 className="description">{movie ? movie.overview : ""} </h2>{" "}
          </>
          : ""}
        </div>
        {movie.rating ?
          <div className={`classificacao-banner box-${movie.rating}`}>
            {movie.rating}
          </div>
        : ""}
          </>
      : ""}

      <div className="fade_bottom"></div>
    </div>
  );
}

export default Banner;
