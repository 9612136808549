import React, { useEffect, useRef, useState } from "react";
import Axios from "../../axios/axios";
import "./ProfileSelection.scss";
import { testing } from "../../constants/constants";


function ProfileSelection({api, onSelect}) {
    const ref = useRef(null);
    const [profiles, setProfiles] = useState([]);
    const [idProfile, setIdprofile] = useState();
    const [loading, setLoading] = useState(false);
    const myBt = null;

    // console.log(testing);
    const [fade, setFade] = useState(false);
    const defaultProfile = 2;

    useEffect(() => {
        Axios.get(api)
          .then((response) => {
            setProfiles(response.data.results);
            if(testing)
            handleProfileClick(null, response.data.results[defaultProfile], defaultProfile)
          })
          .catch((err) => {
            alert("Network Error 1");
          });
    }, [api]);
  
    const triggerFade = () => {
        setFade(prevState => {
        return !prevState
        })
    }


    const handleProfileClick = async (event, item, index) => {
        setFade(prevState => {
            return !prevState
        })

        setIdprofile(index);

        if(event){
            var bt = event.currentTarget;
            bt.classList.add("selected-profile");
            
            var rect = bt.getBoundingClientRect();
            
            var xPos = (window.innerWidth / 2 - bt.offsetWidth / 2) - rect.left;
            var yPos = (window.innerHeight / 2 - bt.offsetHeight / 2) - rect.top + 20;
    
            setTimeout(() => {
                bt.style.transform = "translate(" + xPos + "px, "+ yPos + "px)";
                return true;
            }, 500);
            
            setTimeout(() => {
                setLoading(true);
            }, 1000);            
        }
        // return;
        getUserContentData(item)   
        return;

        
    };

    const getUserContentData = (item) => {
        const tempo = testing ? 0 : 3500;
        Axios.get(`${item.content_path}`)
        .then((response) => {
            let profileData = response.data;
            setTimeout(() => {
                setLoading(false);
                onSelect(item, profileData);
           }, tempo);
          })
          .catch((err) => {
            console.log(item);
          }); 
    }


    let profiles_list = profiles.map((item, index) => {
        return (            
            <div className="col-6 col-md-2"
            key={index}
            >                    
                <button 
                    ref={myBt}
                    onClick={(event) => handleProfileClick(event, item, index)}
                    className={((fade && idProfile != index) ? "hidden" : "") + " btn bt-profile tr-all"}                                     
                    >
                        <img className="img-fluid" src={item.poster_path} alt="" />
                        <div className="text-white">
                            {item.name}
                        </div>
                </button>
            </div>
        );
    });
    
  return (
    <div className="profile-selection py-5">
        <div className="container m-auto">
            <div className={(fade ? "hidden" : "") + " row tr-all"}>
                <div className="col-12">
                    <p className="text-white text-center h1 mb-5">Quem você vai assistir agora?</p>
                </div>
            </div>
            {loading &&
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            }
            
            <div className="row justify-content-center">
                {profiles_list}                
            </div>
        </div>
    </div>
  );
}

export default ProfileSelection;
