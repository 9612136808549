import React, { useEffect, useState } from "react";
import Axios from "../../axios/axios";
import { imageUrl, baseUrl, API_KEY } from "../../constants/constants";
import "./RowPost.scss";
import ShowTrailer from "../ShowTrailer/ShowTrailer";

function RowPost({ onOpen, onClose, title, isSmall, content }) {
  const [movies, setMovies] = useState([]);
  const [videoKey, setVideoKey] = useState();
  const [videoContent, setContent] = useState();

  useEffect(() => {
    setMovies(content);    
    return;
  }, []);


  const handleMovieClick = (item) => {
    onOpen(item);

    return;
    Axios.get(`${baseUrl}/movie/${item.id}/videos?api_key=${API_KEY}`)
      .then((response) => {
        console.log(response.data);
        let videoData = response.data.results[0];
        console.log(videoData);
        setVideoKey(videoData.key);
      })
      .catch((err) => {
        alert("Video is not found..,Please choose another Movie...");
      });

    console.log(videoKey);
  };
  let display = movies.map((item, index) => {
    // console.log(index);
    return (
      <div
        key={index}      
      >
      <div        
        className="position-relative me-2">
      <img
        onClick={() => handleMovieClick(item)}
        className={isSmall ? "small-poster" : "poster"}
        src={imageUrl + item.backdrop_path}
        alt=""
        />
        <div className="progress-bar" style={{
          width: `${(item.progress * 100) || 100}%`
        }}>
        </div>
        </div>
      </div>
    );
  });
  const handleShow = () => {
    setVideoKey(undefined);
  };

  return (
    <>
      <h2 className="h5 text-white mb-3">{title}</h2>
      <div className="row mb-5" onClick={handleShow}>
        <div className="col-12">
          {movies ?
            <div className="posters" onClick={handleShow}>
              {display}
            </div>
              : "Nenhum item para exibir"
          }
        </div>
      </div>      
    </>
  );
}

export default RowPost;
