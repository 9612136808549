import React, { useEffect, useState, useRef } from "react";
import { imageUrl, baseUrl, API_KEY, testing } from "./constants/constants";

import AppNavBar from "./Components/AppNavBar/AppNavBar";
import ProfileSelection from "./Components/ProfileSelection/ProfileSelection";
import "./App.scss";
import Axios from "axios";

import Banner from "./Components/Banner/Banner";
import RowPost from "./Components/RowPost/RowPost";
import ShowTrailer from "./Components/ShowTrailer/ShowTrailer";
import { Transition } from 'react-transition-group';

import 'bootstrap/dist/js/bootstrap.min.js';


// import 'bootstrap/dist/css/bootstrap-grid.css';
// import 'bootstrap/dist/css/bootstrap-utilities.css';
// import 'bootstrap/dist/css/bootstrap-reboot.css';
// import 'bootstrap/dist/css/bootstrap.css';

import {
  actions,
  comedy,
  horror,
  profiles,
  originals,
  bombar,
  romance,
} from "./constants/urls";

function App() {
  const [videoKey, setVideoKey] = useState();
  const [showIntro, setIntro] = useState(false);
  const [start, setStart] = useState(false);
  const [homeContent, setProfile] = useState();
  const [user, setUser] = useState();
  const [contentData, setCont] = useState();
  const nodeRef = useRef(null);


  useEffect(() => {
    setIntro(testing ? false : true);
    setStart(testing ? false : true);
  },[]);

  
  
  const vidRef = useRef(null);  

  const carregaConteudo = (data) => {
    console.log(data);
    setCont(data);
  };

  const fechaConteudo = (data) => {
    setCont();
  };
  
  const selectProfile = (userData, data) => {
    console.log(userData, data);
    // console.log(userData);
    setUser(userData);
    setProfile(data);
  };

  const showVideo = () => {
    vidRef.current.play();
    setStart(false);
  }

  const onVideoEnd = () => {
    setIntro(false);
  }

  const handlePlayVideo = () => {
  }

  
  return (
    <div className={`App ${((contentData || showIntro) ? "show-content" : "")}`}>
            
               

    {homeContent &&
      <>
      <AppNavBar setProfile={() => setProfile()} user={user} />
      <Banner content={homeContent.banner.results} onOpen={carregaConteudo} />
      <div className="container-fluid">
        <RowPost title={homeContent.main.heading || "O que tá bombando"} onOpen={carregaConteudo} content={homeContent.main.results} />
        <RowPost title={homeContent.related.heading || "O que ainda vai bombar (e muito)"} onOpen={carregaConteudo} isSmall={true} content={homeContent.related.results}  />
      </div>
      </>

    }      

    <Transition nodeRef={nodeRef} in={contentData} exit={!contentData} timeout={500}>
        {state => (  
          <>
          {contentData &&
            <div ref={nodeRef} className={`show-container modal-content tr-all fade-el fade-${state}  ${state}`}>
              <ShowTrailer data={contentData} onClose={fechaConteudo}></ShowTrailer>
            </div>
          }
          </>

        )}
    </Transition>

      {!homeContent &&
    <Transition nodeRef={nodeRef} in={!homeContent} exit={homeContent} timeout={500}>
      {state => (
            <div ref={nodeRef} className={`tr-all fade-el fade-${state} ${state}`}>
                  <ProfileSelection  api={profiles} onSelect={selectProfile}></ProfileSelection>
            </div>
      )}
    </Transition>
      }
      {showIntro &&
      <>
      {start &&
        <button className="bt-init" onClick={showVideo}>Iniciar</button>
      }
      
      <div id="anima">
        <div className="anima-anbima">
          <video ref={vidRef} onEnded={() => onVideoEnd()}> 
            <source src="./images/logo-anbflix-intro.mp4" type="video/mp4"/>
          </video>
        </div>
      </div>
      </>
      }
    </div>
  );
}

export default App;
